<template>
  <transition name="fade" appear>
    <div v-if="show" class="opacity-50 fixed inset-0 z-50 bg-black"></div>
  </transition>
  <transition
    enter-active-class="animated bounceIn"
    leave-active-class="animated bounceOut"
    appear
  >
    <div
      v-if="show"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex z-51"
    >
      <div class="relative w-full md:w-1/3 my-6 mx-4 md:mx-auto">
        <!--content-->
        <div
          class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[80vh]"
        >
          <!--header-->
          <!-- <div class="flex items-start justify-end p-3 pb-0 rounded-t">
            <div
              class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
            >
              <svg
                class="fill-current text-[#383838]"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                ></path>
              </svg>
            </div>
          </div> -->
          <!--body-->
          <div class="p-6 flex-auto overflow-x-hiden overflow-y-auto">
            <img
              src="/assets/images/modal/illustration-success.svg"
              class="m-auto w-[12rem]"
              alt=""
            />
            <p
              class="text-[#212121] font-desain font-semibold text-lg py-3 text-center"
            >
              Yach! Semangat kakak. Ayo mulai belajar supaya bisa lebih baik
              lagi.
            </p>

            <div
              class="grid grid-cols-3 gap-1 bg-[#F6FBFF] p-3 rounded-md mb-3"
            >
              <div class="col-span-1 flex flex-col items-center space-y-2">
                <span class="font-solusi font-semibold text-[#212121]"
                  >Benar</span
                >
                <span
                  class="font-desain font-semibold text-[#2FBD71] text-lg"
                  >{{ countTrue }}</span
                >
              </div>
              <div class="col-span-1 flex flex-col items-center space-y-2">
                <span class="font-solusi font-semibold text-[#212121]"
                  >Salah</span
                >
                <span
                  class="font-desain font-semibold text-[#DE1306] text-lg"
                  >{{ countFalse }}</span
                >
              </div>
              <div class="col-span-1 flex flex-col items-center  space-y-2">
                <span class="font-solusi font-semibold text-[#212121]"
                  >Nilai</span
                >
                <span
                  class="font-desain font-semibold text-[#009DFF] text-lg"
                  >{{ score }}</span
                >
              </div>
            </div>
            <div class="grid gap-3 grid-cols-1">
              <button
                @click="next()"
                class="font-solusi text-[#FFFFFF] rounded-full bg-[#00446F] font-semibold py-2"
              >
                Lanjut Belajar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal Pre Test',
  props: {
    show: Boolean,
    countTrue: Number,
    countFalse: Number,
    score: Number,
    next: Function,
  },
}
</script>

<style></style>
