<template>
  <transition name="fade">
    <div v-if="show" class="modal-overlay z-30"></div>
  </transition>
  <transition
    enter-active-class="animated bounceIn"
    leave-active-class="animated bounceOut"
    v-on:after-enter="afterEnter"
  >
    <div v-if="show" class="modal z-40" id="popup">
      <!-- Modal content -->
      <div class="modal-content">
        <img
          :src="
            score >= minScore
              ? '/assets/images/modal/quiz-success.png'
              : '/assets/images/modal/quiz-failed.png'
          "
          alt="modal-image"
          class="modal-image"
        />
        <p class="first-text">{{ score >= minScore ? 'Horee!' : 'Yach!' }}</p>
        <p class="second-text">
          {{
            score >= minScore
              ? 'Kamu lulus ' + quizName + '! Kamu memang jagoan!'
              : 'Kamu ga lulus ' + quizName + ', Tetap semangat ya!'
          }}
        </p>
        <div class="d-flex justify-content-around mb-3">
          <div class="text-center">
            <p class="text-quiz-1 text-secondary">Benar</p>
            <p class="text-quiz-2">{{ countTrue }}</p>
          </div>
          <div class="text-center">
            <p class="text-quiz-1 text-secondary">Salah</p>
            <p class="text-quiz-2">{{ countFalse }}</p>
          </div>
          <div class="text-center">
            <p class="text-quiz-1 text-secondary">Nilai</p>
            <p
              class="text-quiz-2"
              :class="score >= minScore ? 'text-warning' : 'text-danger'"
            >
              {{ score }}
            </p>
          </div>
        </div>
        <p v-if="score < minScore" class="text-secondary text-quiz-1">
          Minimal Nilai: {{ minScore }}
        </p>
        <div class="button-container">
          <a id="auto-close" class="d-block first-button" @click.prevent="next"
            >Lanjut Materi Berikutnya</a
          >
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ModalPack',
  props: {
    show: Boolean,
    quizName: String,
    countTrue: Number,
    countFalse: Number,
    score: Number,
    minScore: Number,
    next: Function,
  },
  methods: {
    afterEnter() {
      if (this.duration > 0) {
        setTimeout(() => {
          document.getElementById('auto-close').click()
        }, this.duration)
      }
    },
  },
}
</script>
<style scoped>
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
/* The Modal (background) */
.modal {
  display: flex;
  justify-content: center;
  position: fixed; /* Stay in place */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background: transparent;
}

/* Modal Content */
.modal-content {
  background-color: #ffffff;
  border-radius: 13.5px;
  margin: auto;
  padding: 20px 25px;
  width: 80%;
  text-align: center;
  border: none;
}

.modal-image {
  width: 150px;
  height: auto;
  margin: 0 auto;
}
.first-text {
  font-family: 'Poppins', sans-serif;
  width: 90%;
  margin: 10px auto;
  font-size: 1.5rem;
  font-weight: 800;
}

.second-text {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.button-container {
  margin: 0 auto;
}
.first-button,
.second-button {
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  font-weight: bold;
  border-radius: 8px;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  margin: 0 10px;
}
.first-button {
  background: #0070b5;
  color: #fff;
}
.first-button:hover {
  background: #005285;
}
.second-button {
  background: #fff;
  color: #0070b5;
  border: 2px solid #0070b5;
}
.w-70 {
  width: 90%;
}

.text-quiz-1 {
  font-size: 0.9rem;
  margin: 0;
  font-weight: 600;
}

.text-quiz-2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 800;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s linear;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (min-width: 768px) {
  .modal-content {
    width: 400px;
    padding: 1.5rem;
  }
}

.animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}

@-webkit-keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
</style>
