<template>
  <div
    class="flex flex-col rounded-lg md:rounded-md shadow-md md:hover:shadow-lg mb-4 p-2 bg-[#FFFFFF] border border-gray-50 duration-300"
  >
    <div class="flex flex-row space-x-3 md:mb-0 mb-3">
      <img
        :src="
          webinar.thumbnail ? webinar.thumbnail : webinar.classroom.thumbnail
        "
        class="w-36 md:w-64 h-36 rounded-md object-cover object-center"
        alt=""
      />
      <div
        class="flex-auto flex flex-col h-full justify-between w-full overflow-hidden"
      >
        <div class="flex flex-col">
          <p
            class="font-desain text-lg md:text-xl font-semibold text-[#383838] mb-2 line-clamp-2 overflow-hidden"
          >
            {{ webinar.name }}
          </p>
          <p class="font-solusi text-[#212121]">
            {{ webinarTimeFormatted(webinar.started_at, webinar.expired_at) }}
          </p>
          <p v-if="coming" class="font-solusi text-[#009DFF] font-semibold ">
            AKAN DIMULAI {{ dayLeft }} HARI LAGI
          </p>
          <p v-if="soon" class="font-solusi text-[#FB8500] font-semibold ">
            AKAN {{ minuteLeft }}
          </p>
          <p
            v-if="notStartedYet"
            class="font-solusi font-semibold "
            :class="live ? 'text-[#2FBD71]' : 'text-[#9E9E9E]'"
          >
            {{ live ? 'WEBINAR SEDANG BERLANGSUNG' : 'WEBINAR TELAH BERAKHIR' }}
          </p>
        </div>
        <div class="hidden md:flex flex-row items-center space-x-2 mt-1">
          <div v-if="preTest.length > 0">
            <div v-for="preTest in preTest" :key="preTest.id">
              <button
                @click="onClickPreTest(preTest)"
                v-if="preTest.webinar_id == webinar.id"
                class="w-fit-content font-solusi text-base rounded-full px-4 py-2 font-semibold flex items-center"
                :class="
                  (openPre || dateLong(preTest.started_at) <= dateNow()) &&
                  dateLong(preTest.ended_at) >= dateNow()
                    ? 'text-[#FFFFFF] bg-[#00446F]'
                    : 'text-[#9E9E9E] bg-[#F5F6F8]'
                "
              >
                <svg
                  v-if="preTest.is_done"
                  class="w-5 h-5 mr-1"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12.5" cy="12" r="12" fill="#009DFF" />
                  <path
                    d="M9.77561 17.9112C10.0503 18.193 10.4325 18.3527 10.832 18.3527C10.8359 18.3527 10.8399 18.3527 10.8442 18.3527C11.2483 18.3493 11.6327 18.1833 11.9052 17.8938L19.8862 9.41738C20.4273 8.84272 20.3852 7.95152 19.7928 7.42683C19.2002 6.90251 18.2812 6.9429 17.7401 7.51738L10.814 14.8732L8.658 12.6593C8.10666 12.0934 7.18685 12.0683 6.60373 12.6023C6.02023 13.1366 5.99427 14.0282 6.54503 14.594L9.77561 17.9112Z"
                    fill="white"
                  />
                </svg>
                Pre-Test
              </button>
            </div>
          </div>
          <button
            @click="
              onClickWebinar(webinar.id, webinar.started_at, webinar.expired_at)
            "
            class="w-fit-content font-solusi text-base rounded-full px-4 py-2 font-semibold flex items-center"
            :class="
              shouldLive && live
                ? 'text-[#FFFFFF] bg-[#00446F]'
                : 'text-[#9E9E9E] bg-[#F5F6F8]'
            "
          >
            <svg
              v-if="checkPostTest(postTest, webinar.id)"
              class="w-5 h-5 mr-1"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12.5" cy="12" r="12" fill="#009DFF" />
              <path
                d="M9.77561 17.9112C10.0503 18.193 10.4325 18.3527 10.832 18.3527C10.8359 18.3527 10.8399 18.3527 10.8442 18.3527C11.2483 18.3493 11.6327 18.1833 11.9052 17.8938L19.8862 9.41738C20.4273 8.84272 20.3852 7.95152 19.7928 7.42683C19.2002 6.90251 18.2812 6.9429 17.7401 7.51738L10.814 14.8732L8.658 12.6593C8.10666 12.0934 7.18685 12.0683 6.60373 12.6023C6.02023 13.1366 5.99427 14.0282 6.54503 14.594L9.77561 17.9112Z"
                fill="white"
              />
            </svg>
            Join Webinar
          </button>
          <div v-if="postTest.length > 0">
            <div v-for="postTest in postTest" :key="postTest.id">
              <button
                @click="onClickPostTest(postTest)"
                v-if="postTest.webinar_id == webinar.id"
                class="w-fit-content font-solusi text-base rounded-full px-4 py-2 font-semibold flex items-center"
                :class="
                  (openPost || dateLong(postTest.started_at) <= dateNow()) &&
                  dateLong(postTest.ended_at) >= dateNow()
                    ? 'text-[#FFFFFF] bg-[#00446F]'
                    : 'text-[#9E9E9E] bg-[#F5F6F8]'
                "
              >
                <svg
                  v-if="postTest.is_done"
                  class="w-5 h-5 mr-1"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12.5" cy="12" r="12" fill="#009DFF" />
                  <path
                    d="M9.77561 17.9112C10.0503 18.193 10.4325 18.3527 10.832 18.3527C10.8359 18.3527 10.8399 18.3527 10.8442 18.3527C11.2483 18.3493 11.6327 18.1833 11.9052 17.8938L19.8862 9.41738C20.4273 8.84272 20.3852 7.95152 19.7928 7.42683C19.2002 6.90251 18.2812 6.9429 17.7401 7.51738L10.814 14.8732L8.658 12.6593C8.10666 12.0934 7.18685 12.0683 6.60373 12.6023C6.02023 13.1366 5.99427 14.0282 6.54503 14.594L9.77561 17.9112Z"
                    fill="white"
                  />
                </svg>
                Post-Test
              </button>
            </div>
          </div>
          <button
            v-if="
              webinar?.can_request_plus &&
                dateLong(webinar.request_plus_expired_at) >= dateNow()
            "
            @click="
              onClickRequestWebinarPlus(
                webinar?.id,
                webinar?.request_plus_started_at,
                webinar?.request_plus_expired_at,
                webinar?.classroom?.name
              )
            "
            class="w-fit-content font-solusi text-base rounded-full px-3 py-2 font-semibold flex items-center text-[#FFFFFF] bg-[#00446F]"
          >
            Request Webinar Plus
          </button>
        </div>
      </div>
    </div>
    <div
      class="flex md:hidden flex-row items-center space-x-2"
      :class="
        isPreTestExist(preTest, webinar.id) ? 'justify-evenly' : 'justify-end'
      "
    >
      <div v-if="preTest.length > 0">
        <div v-for="preTest in preTest" :key="preTest.id">
          <button
            @click="onClickPreTest(preTest)"
            v-if="preTest.webinar_id == webinar.id"
            class="w-fit-content font-solusi text-base rounded-full px-4 py-2 font-semibold flex items-center"
            :class="
              (openPre || dateLong(preTest.started_at) <= dateNow()) &&
              dateLong(preTest.ended_at) >= dateNow()
                ? 'text-[#FFFFFF] bg-[#00446F]'
                : 'text-[#9E9E9E] bg-[#F5F6F8]'
            "
          >
            <svg
              v-if="preTest.is_done"
              class="w-5 h-5 mr-1"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12.5" cy="12" r="12" fill="#009DFF" />
              <path
                d="M9.77561 17.9112C10.0503 18.193 10.4325 18.3527 10.832 18.3527C10.8359 18.3527 10.8399 18.3527 10.8442 18.3527C11.2483 18.3493 11.6327 18.1833 11.9052 17.8938L19.8862 9.41738C20.4273 8.84272 20.3852 7.95152 19.7928 7.42683C19.2002 6.90251 18.2812 6.9429 17.7401 7.51738L10.814 14.8732L8.658 12.6593C8.10666 12.0934 7.18685 12.0683 6.60373 12.6023C6.02023 13.1366 5.99427 14.0282 6.54503 14.594L9.77561 17.9112Z"
                fill="white"
              />
            </svg>
            Pre-Test
          </button>
        </div>
      </div>
      <button
        @click="
          onClickWebinar(webinar.id, webinar.started_at, webinar.expired_at)
        "
        class="w-fit-content font-solusi text-base rounded-full px-4 py-2 font-semibold flex items-center"
        :class="
          shouldLive && live
            ? 'text-[#FFFFFF] bg-[#00446F]'
            : 'text-[#9E9E9E] bg-[#F5F6F8]'
        "
      >
        <svg
          v-if="checkPostTest(postTest, webinar.id)"
          class="w-5 h-5 mr-1"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12.5" cy="12" r="12" fill="#009DFF" />
          <path
            d="M9.77561 17.9112C10.0503 18.193 10.4325 18.3527 10.832 18.3527C10.8359 18.3527 10.8399 18.3527 10.8442 18.3527C11.2483 18.3493 11.6327 18.1833 11.9052 17.8938L19.8862 9.41738C20.4273 8.84272 20.3852 7.95152 19.7928 7.42683C19.2002 6.90251 18.2812 6.9429 17.7401 7.51738L10.814 14.8732L8.658 12.6593C8.10666 12.0934 7.18685 12.0683 6.60373 12.6023C6.02023 13.1366 5.99427 14.0282 6.54503 14.594L9.77561 17.9112Z"
            fill="white"
          />
        </svg>
        Join Webinar
      </button>
      <div v-if="postTest.length > 0">
        <div v-for="postTest in postTest" :key="postTest.id">
          <button
            @click="onClickPostTest(postTest)"
            v-if="postTest.webinar_id == webinar.id"
            class="w-fit-content font-solusi text-base rounded-full px-4 py-2 font-semibold flex items-center"
            :class="
              (openPost || dateLong(postTest.started_at) <= dateNow()) &&
              dateLong(postTest.ended_at) >= dateNow()
                ? 'text-[#FFFFFF] bg-[#00446F]'
                : 'text-[#9E9E9E] bg-[#F5F6F8]'
            "
          >
            <svg
              v-if="postTest.is_done"
              class="w-5 h-5 mr-1"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12.5" cy="12" r="12" fill="#009DFF" />
              <path
                d="M9.77561 17.9112C10.0503 18.193 10.4325 18.3527 10.832 18.3527C10.8359 18.3527 10.8399 18.3527 10.8442 18.3527C11.2483 18.3493 11.6327 18.1833 11.9052 17.8938L19.8862 9.41738C20.4273 8.84272 20.3852 7.95152 19.7928 7.42683C19.2002 6.90251 18.2812 6.9429 17.7401 7.51738L10.814 14.8732L8.658 12.6593C8.10666 12.0934 7.18685 12.0683 6.60373 12.6023C6.02023 13.1366 5.99427 14.0282 6.54503 14.594L9.77561 17.9112Z"
                fill="white"
              />
            </svg>
            Post-Test
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { parseDate } from '@/utils/Util'
import { Webinar } from '@/dto'
import moment from 'moment'
import 'fix-date'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Webinar',
  props: {
    startDate: String,
    expiredDate: String,
    onClickWebinar: Function,
    onClickPreTest: Function,
    onClickPostTest: Function,
    preTest: Array as any,
    postTest: Array as any,
    webinar: Object as Webinar | any,
    onClickRequestWebinarPlus: Function,
  },
  data() {
    return {
      coming: false,
      soon: false,
      live: false,
      notStartedYet: false,
      timer: 0 as number,
      shouldLive: false,
      openPre: false,
      openPost: false,
      minuteLeft: '' as any,
      dayLeft: '' as any,
    }
  },
  mounted() {
    this.refresh()

    if ((this.coming || this.soon) && !this.live) {
      window.setInterval(() => {
        this.refresh()
      }, 5000)
    }

    window.setInterval(() => {
      const isDone = this.$props.preTest.find(
        (e: any) => e.webinar_id === this.$props.webinar?.id
      )?.is_done
      const isDonePost = this.$props.postTest.find(
        (e: any) => e.webinar_id === this.$props.webinar?.id
      )?.is_done
      const preStart = this.$props.preTest.find(
        (e: any) => e.webinar_id === this.$props.webinar?.id
      )?.started_at
      const postStart = this.$props.postTest.find(
        (e: any) => e.webinar_id === this.$props.webinar?.id
      )?.started_at

      if (!isDone) {
        if (this.refreshTest(preStart)) {
          this.openPre = true
        }
      }

      if (!isDonePost) {
        if (this.refreshTest(postStart)) {
          this.openPost = true
        }
      }
    }, 5000)
  },
  methods: {
    refreshTest(date: string) {
      if (this.dateLong(date) <= this.dateNow()) {
        return true
      } else {
        return false
      }
    },

    refresh() {
      this.coming = false
      this.soon = false
      this.live = false
      this.notStartedYet = false
      this.shouldLive = false

      if (this.dateLong(this.$props.webinar.started_at) <= this.dateNow()) {
        this.shouldLive = true
      }

      if (this.dateOnLive(this.$props.webinar.started_at) >= this.dateNow()) {
        this.coming = true
        this.getDayLeft(this.$props.webinar.started_at)
      }

      if (
        this.dateOnLive(this.$props.webinar.started_at) <= this.dateNow() &&
        this.dateLong(this.$props.webinar.started_at) >= this.dateNow()
      ) {
        this.getMinuteLeft(this.$props.webinar.started_at)
        this.soon = true
      }

      if (
        this.dateLong(this.$props.webinar.started_at) <= this.dateNow() &&
        this.dateLong(this.$props.webinar.expired_at) >= this.dateNow()
      ) {
        this.coming = false
        this.live = true
        this.soon = false
      }

      if (this.dateLong(this.$props.webinar.started_at) < this.dateNow()) {
        this.notStartedYet = true
      }
    },

    getDayLeft(start: string) {
      const webinarStart = moment(this.dateLong(start))
      const dateNow = moment()
      this.dayLeft = webinarStart.diff(dateNow, 'days')
    },

    getMinuteLeft(start: string) {
      const webinarStart = moment(this.dateLong(start))
      const dateNow = moment()
      const hourLeft = webinarStart.diff(dateNow, 'hours')
      const minuteLeft = webinarStart.diff(dateNow, 'minutes')
      if (hourLeft >= 1) {
        this.minuteLeft = 'DIMULAI ' + hourLeft + ' JAM LAGI'
      } else if (minuteLeft >= 1) {
        this.minuteLeft =
          'DIMULAI ' + webinarStart.diff(dateNow, 'minutes') + ' MENIT LAGI'
      } else {
        this.minuteLeft = 'SEGERA DIMULAI'
      }
    },

    formatDate: function(date: string) {
      const [formattedDate, formattedTime, timeZone] = parseDate(date)
      return formattedDate + ' ' + formattedTime + ' ' + timeZone
    },
    realDate: function(start: string, end: string) {
      const [formattedDate, formattedTime, timeZone] = parseDate(start)
      const [formattedDateEnd, formattedTimeEnd, timeZoneEnd] = parseDate(end)
      return (
        formattedDate +
        ' ' +
        formattedTime +
        '-' +
        formattedTimeEnd +
        ' ' +
        timeZone
      )
    },
    dateLong(date: string | undefined) {
      const dt = new Date(date as string)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }

      return moment(dt).format('YYYY-MM-DD HH:mm:ss')
    },
    dateNow() {
      return moment().format('YYYY-MM-DD HH:mm:ss')
    },
    dateOnLive(date: string | undefined) {
      const dt = new Date(date as string)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }
      return moment(dt)
        .subtract(1, 'days')
        .format('YYYY-MM-DD HH:mm:ss')
    },
    checkPostTest(data: any, id: number) {
      const get = data.some(
        (el: any) => el.is_done === true && el.webinar_id == id
      )
      return get
    },

    webinarTimeFormatted(start: string, end: string) {
      const startDate = moment(start)
        .locale('id')
        .format('DD MMMM YYYY HH:mm')
      const endDate = moment(end)
        .locale('id')
        .format('HH:mm')
      return startDate + ' - ' + endDate + ' WIB'
    },

    isPreTestExist(preTest: any, webinarId: number) {
      if (preTest.length == 0) {
        return false
      } else {
        if (preTest.some((el: any) => el.webinar_id == webinarId)) {
          return true
        } else {
          return false
        }
      }
    },
  },
})
</script>
