
import { parseDate } from '@/utils/Util'
import moment from 'moment'
import 'fix-date'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Webinar',
  props: {
    idWebinar: Number,
    classroomTitle: String,
    startDate: String,
    expiredDate: String,
    link: String,
    classroomIcon: String,
    onClickWebinar: Function,
    onClickPreTest: Function,
    onClickPostTest: Function,
    preTest: Array as any,
    postTest: Array as any,
    webinarDone: Boolean,
    webinarName: String,
    webinarDetail: Object,
    onClickRequestWebinarPlus: Function,
  },
  data() {
    return {
      soon: false,
      live: false,
      notStartedYet: false,
      timer: 0 as number,
      shouldLive: false,
      openPre: false,
      openPost: false,
    }
  },
  mounted() {
    this.refresh()

    if (this.soon && !this.live) {
      window.setInterval(() => {
        this.refresh()
      }, 5000)
    }

    window.setInterval(() => {
      const isDone = this.$props.preTest.find(
        (e: any) => e.webinar_id === this.idWebinar
      )?.is_done
      const isDonePost = this.$props.postTest.find(
        (e: any) => e.webinar_id === this.idWebinar
      )?.is_done
      const preStart = this.$props.preTest.find(
        (e: any) => e.webinar_id === this.idWebinar
      )?.started_at
      const postStart = this.$props.postTest.find(
        (e: any) => e.webinar_id === this.idWebinar
      )?.started_at

      if (!isDone) {
        if (this.refreshTest(preStart)) {
          this.openPre = true
        }
      }

      if (!isDonePost) {
        if (this.refreshTest(postStart)) {
          this.openPost = true
        }
      }
    }, 5000)
  },
  methods: {
    refreshTest(date: string) {
      if (this.dateLong(date) <= this.dateNow()) {
        return true
      } else {
        return false
      }
    },

    refresh() {
      this.soon = false
      this.live = false
      this.notStartedYet = false
      this.shouldLive = false

      if (this.dateLong(this.$props.startDate) <= this.dateNow()) {
        this.shouldLive = true
      }

      if (
        this.dateOnLive(this.$props.startDate) <= this.dateNow() &&
        this.dateLong(this.$props.startDate) >= this.dateNow()
      ) {
        this.soon = true
      }

      if (
        this.dateLong(this.$props.startDate) <= this.dateNow() &&
        this.dateLong(this.$props.expiredDate) >= this.dateNow()
      ) {
        this.live = true
        this.soon = false
      }

      if (this.dateLong(this.$props.startDate) < this.dateNow()) {
        this.notStartedYet = true
      }
    },

    formatDate: function(date: string) {
      const [formattedDate, formattedTime, timeZone] = parseDate(date)
      return formattedDate + ' ' + formattedTime + ' ' + timeZone
    },
    realDate: function(start: string, end: string) {
      const [formattedDate, formattedTime, timeZone] = parseDate(start)
      const [formattedDateEnd, formattedTimeEnd, timeZoneEnd] = parseDate(end)
      return (
        formattedDate +
        ' ' +
        formattedTime +
        '-' +
        formattedTimeEnd +
        ' ' +
        timeZone
      )
    },
    dateLong(date: string | undefined) {
      const dt = new Date(date as string)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }

      return moment(dt).format('YYYY-MM-DD HH:mm:ss')
    },
    dateNow() {
      return moment().format('YYYY-MM-DD HH:mm:ss')
    },
    dateOnLive(date: string | undefined) {
      const dt = new Date(date as string)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }
      return moment(dt)
        .subtract(60, 'minutes')
        .format('YYYY-MM-DD HH:mm:ss')
    },
    checkPostTest(data: any, id: number) {
      const get = data.some(
        (el: any) => el.is_done === true && el.webinar_id == id
      )
      return get
    },
  },
})
