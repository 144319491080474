<template>
  <transition name="fade" appear>
    <div v-if="show" class="opacity-50 fixed inset-0 z-50 bg-black"></div>
  </transition>
  <transition
    enter-active-class="animated bounceIn"
    leave-active-class="animated zoomOut"
    appear
  >
    <div
      v-if="show"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex z-51"
    >
      <div class="relative w-full md:w-1/3 my-6 mx-4 md:mx-auto">
        <!--content-->
        <div
          v-click-away="close"
          class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[80vh]"
        >
          <!--body-->
          <div class="p-6 flex-auto overflow-x-hiden overflow-y-auto">
            <lottie-player
              v-if="status == 'success'"
              src="https://assets1.lottiefiles.com/private_files/lf30_dCzDJu.json"
              background="transparent"
              speed="1.5"
              class="m-auto w-[100px]"
              autoplay
            ></lottie-player>
            <lottie-player
              v-else-if="status == 'error'"
              src="https://assets1.lottiefiles.com/private_files/lf30_QYPL9z.json"
              background="transparent"
              speed="1.5"
              class="m-auto w-[100px]"
              autoplay
            ></lottie-player>
            <p
              v-if="title"
              class="font-solusi font-semibold mb-2 text-center text-[#383838] text-3xl"
            >
              {{ title }}
            </p>
            <p
              v-if="description"
              class="font-solusi font-medium text-lg text-center text-[#383838]"
            >
              {{ description }}
            </p>
            <div class="text-center mt-5">
              <button
                @click="close()"
                class="bg-[#00446F] hover:bg-[#073857] px-8 py-2 text-white font-solusi font-semibold rounded-md outline-none focus:outline-none ring"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal Status',
  props: {
    show: Boolean,
    status: String,
    title: String,
    description: String,
    close: Function,
  },
}
</script>

<style></style>
