<template>
  <transition name="fade">
    <div v-if="show" class="modal-overlay z-30"></div>
  </transition>
  <transition
    enter-active-class="animated bounceIn"
    leave-active-class="animated bounceOut"
    v-on:after-enter="afterEnter"
  >
    <div v-if="show" class="modal z-40" id="popup">
      <!-- Modal content -->
      <div class="modal-content rounded-md">
        <img
          :src="imageUrl"
          alt="modal-image"
          class="modal-image"
          :style="imageWidth != null ? 'width:' + imageWidth : ''"
        />
        <p class="first-text">{{ firstText }}</p>
        <p v-if="secondText != ''" class="second-text">{{ secondText }}</p>

        <div
          class="button-container"
          :class="secondButton != '' ? 'flex justify-center w-full' : 'w-[70%]'"
        >
          <a
            v-if="secondButton != ''"
            @click.prevent="secondButtonAction"
            class="second-button"
            >{{ secondButton }}</a
          >
          <a
            id="auto-close"
            :class="secondButton != '' ? '' : 'block'"
            @click.prevent="firstButtonAction"
            class="first-button"
            >{{ firstButton }}</a
          >
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ModalPack',
  props: {
    show: Boolean,
    imageUrl: String,
    firstText: String,
    secondText: String,
    firstButton: String,
    firstButtonAction: Function,
    secondButton: String,
    secondButtonAction: Function,
    duration: Number,
    imageWidth: String,
  },
  methods: {
    afterEnter() {
      if (this.duration > 0) {
        setTimeout(() => {
          document.getElementById('auto-close').click()
        }, this.duration)
      }
    },
  },
}
</script>
<style scoped>
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  /* z-index: 4; */
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
/* The Modal (background) */
.modal {
  display: flex;
  justify-content: center;
  position: fixed; /* Stay in place */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background: transparent;
}

/* Modal Content */
.modal-content {
  background-color: #ffffff;
  /* border-radius: 13.5px; */
  margin: auto;
  padding: 20px 25px;
  width: 90%;
  text-align: center;
  border: none;
}

.modal-image {
  width: 200px;
  height: auto;
  margin: 0 auto;
}
.first-text {
  font-family: 'SolusiSans', 'Poppins', sans-serif;
  width: 90%;
  margin: 10px auto;
  font-size: 1.25rem;
  font-weight: 800;
}

.second-text {
  font-family: 'SolusiSans', 'Poppins', sans-serif;
  color: rgba(0, 0, 0, 0.7);
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.button-container {
  margin: 0 auto;
}
.first-button,
.second-button {
  font-family: 'SolusiSans', 'Poppins', sans-serif;
  cursor: pointer;
  font-weight: bold;
  border-radius: 999px;
  font-size: 1rem;
  padding: 0.5rem 2.2rem;
  margin: 0 10px;
}
.first-button {
  background: #00446f;
  color: #fff;
}
.first-button:hover {
  background: #005285;
}
.second-button {
  background: #fff;
  color: #00446f;
  border: 2px solid #00446f;
}
.w-70 {
  width: 90%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s linear;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (min-width: 768px) {
  .modal-content {
    width: 400px;
    padding: 1.5rem;
  }
}

.animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}

@-webkit-keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
</style>
