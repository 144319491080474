import ResourceResponse from '@/dto/Response/ResourceResponse'
import WebinarQuiz from '@/dto/WebinarQuiz'
import MainApi from '@/utils/Http'

const Http = MainApi.getInstance()
export default class WebinarQuizModel {
  protected endpoint =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_CLASSROOM}`

  protected privateEndpoint =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_CLASSROOM_PRIVATE}`

  protected privateEndpointV2 =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_CLASSROOM_PRIVATE_V2}`

  public static async list(id: number): Promise<ResourceResponse<WebinarQuiz>> {
    const endpoint = new this().privateEndpointV2 + '/webinar-quiz/' + id

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listByClassroom(
    ClassroomId: any
  ): Promise<ResourceResponse<WebinarQuiz>> {
    const endpoint =
      new this().privateEndpoint + '/webinar-quiz/' + ClassroomId + '/classroom'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listItemsById(id: any) {
    const endpoint =
      new this().privateEndpoint + '/webinar-quiz/' + id + '/items'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async submit(id: any, payload: any) {
    const endpoint =
      new this().privateEndpoint + '/webinar-quiz/' + id + '/submit'

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async submitV2(id: any, payload: any) {
    const endpoint =
      new this().privateEndpointV2 + '/webinar-quiz/' + id + '/submit'

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async detail(
    id: number
  ): Promise<ResourceResponse<WebinarQuiz>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async update(
    id: number,
    payload: WebinarQuiz
  ): Promise<ResourceResponse<WebinarQuiz>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.put(`${endpoint}/${id}`, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async destroy(
    id: number
  ): Promise<ResourceResponse<WebinarQuiz>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getWebinarQuiz(
    id: any
  ): Promise<ResourceResponse<WebinarQuiz>> {
    const endpoint = new this().privateEndpoint + '/webinar-quiz/' + id

    const {
      data: { data, success },
    } = await Http.get(endpoint)
    return {
      success,
      data,
    }
  }

  public static async getWebinarQuizV2(
    id: any
  ): Promise<ResourceResponse<WebinarQuiz>> {
    const endpoint = new this().privateEndpointV2 + '/webinar-quiz/' + id

    const {
      data: { data, success },
    } = await Http.get(endpoint)
    return {
      success,
      data,
    }
  }
}
