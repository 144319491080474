<template>
  <transition name="fade">
    <div v-if="show" class="modal-overlay z-30"></div>
  </transition>
  <transition
    enter-active-class="animated bounceIn"
    leave-active-class="animated zoomOut"
  >
    <div v-if="show" class="modal z-40" id="popup">
      <!-- Modal content -->
      <div v-if="!showInput" class="modal-content">
        <img
          :src="imageUrl"
          alt="modal-image"
          class="modal-image"
          :style="imageWidth != null ? 'width:' + imageWidth : ''"
        />
        <p class="first-text">{{ firstText }}</p>
        <p v-if="secondText != ''" class="second-text">{{ secondText }}</p>

        <div class="button-container w-70">
          <a
            id="auto-close"
            @click.prevent="next"
            class="next-button d-block"
            >{{ firstButton }}</a
          >
        </div>
      </div>
      <div v-else class="modal-content position-relative">
        <div
          class="position-absolute close-btn cursor-pointer"
          @click="secondButtonAction"
        >
          <svg
            viewBox="0 0 365.696 365.696"
            style="width:1rem;"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#9CA3AF"
              d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0"
            />
          </svg>
        </div>
        <label
          for=""
          class="text-left mt-3"
          style="font-weight:700; line-height:1.25;"
          >Ceritakan alasanmu dibawah mengapa ingin mengikuti Webinar
          Plus</label
        >
        <label
          for=""
          class="text-left mb-1 text-italic blue-sd-2 font-italic"
          style="font-weight: 800;"
          >{{ classroom }}</label
        >
        <textarea
          cols="30"
          rows="7"
          :value="reason"
          @input="event => $emit('update:reason', event.target.value)"
          class="px-2 pt-2 input-reason"
          placeholder="Masukkan alasan anda..."
        ></textarea>
        <p
          v-if="error"
          class="text-left m-0 text-danger"
          style="font-size:.8rem; font-weight:800;"
        >
          Alasan tidak boleh kosong
        </p>
        <div class="d-flex justify-content-between mt-3">
          <a @click="firstButtonAction" class="cursor-pointer next-button w-100"
            >Daftar</a
          >
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalRequestWebinar',
  props: {
    show: Boolean,
    imageUrl: String,
    firstText: String,
    secondText: String,
    firstButton: String,
    firstButtonAction: Function,
    secondButton: String,
    secondButtonAction: Function,
    duration: Number,
    imageWidth: String,
    next: Function,
    showInput: Boolean,
    reason: String,
    classroom: String,
    error: Boolean,
  },
  methods: {},
})
</script>
<style scoped>
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  /* z-index: 4; */
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
/* The Modal (background) */
.modal {
  display: flex;
  justify-content: center;
  position: fixed; /* Stay in place */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background: transparent;
}

/* Modal Content */
.modal-content {
  background-color: #ffffff;
  border-radius: 13.5px;
  margin: auto;
  padding: 20px 25px;
  width: 90%;
  text-align: center;
  border: none;
}

.modal-image {
  width: 200px;
  height: auto;
  margin: 0 auto;
}
.first-text {
  width: 90%;
  margin: 10px auto;
  font-size: 1.4rem;
  font-weight: 800;
}

.second-text {
  color: rgba(0, 0, 0, 0.7);
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}
.button-container {
  margin: 0 auto;
}
.next-button,
.close-button,
.request-button {
  cursor: pointer;
  font-weight: bold;
  border-radius: 8px;
  font-size: 1rem;
  margin: 0;
  color: #fff;
}
.next-button {
  padding: 0.5rem 2.2rem;
  background: #0070b5;
}
.next-button:hover {
  background: #005285;
}
.close-button {
  padding: 0.5rem 1.5rem;
  background: #ef4444;
}
.close-button:hover {
  background: #dc2626;
}
.request-button {
  padding: 0.5rem 1.5rem;
  background: #2563eb;
}
.request-button:hover {
  background: #1e40af;
}

.w-70 {
  width: 90%;
}

.cursor-pointer {
  cursor: pointer;
}

.input-reason {
  outline: none;
  border: none;
  overflow: auto;
  line-height: 1.2;
  font-weight: 600;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
}

.input-reason:focus {
  border: 1px solid #2563eb;
}

.close-btn {
  z-index: 100;
  top: 10px;
  right: 15px;
  padding: 0 5px 3px;
}
.close-btn:hover {
  background-color: #e5e7eb;
}

.close-btn span {
  font-size: 1.2rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s linear;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (min-width: 768px) {
  .modal-content {
    width: 400px;
    padding: 1.5rem;
  }
}

.animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}

@-webkit-keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
</style>
