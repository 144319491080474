import 'fix-date'

export const formatDuration = (duration: string) => {
  const splittedDuration = duration.split(':')
  const hour = +splittedDuration[0]
  const minutes = +splittedDuration[1]
  const seconds = +splittedDuration[2]
  const generateDurationText = () => {
    const durationText = {
      hour: 'jam',
      minutes: 'menit',
      seconds: 'detik',
    }
    if (hour && minutes && seconds) {
      return `${hour} ${durationText.hour} ${minutes} ${durationText.minutes} ${seconds} ${durationText.seconds}`
    } else if (!hour && minutes && seconds) {
      return `${minutes} ${durationText.minutes} ${seconds} ${durationText.seconds}`
    } else if (!hour && !minutes && seconds) {
      return `${seconds} ${durationText.seconds}`
    }
  }
  return generateDurationText()
}

export const parseDate = (stringDate: string) => {
  const date = new Date(stringDate)

  let timeZone
  const offset = new Date().getTimezoneOffset()
  const timeZoneOffset = offset / 60

  switch (timeZoneOffset) {
    case -8:
      timeZone = 'WITA'
      date.setHours(date.getHours() + 1)
      break
    case -9:
      timeZone = 'WIT'
      date.setHours(date.getHours() + 2)
      break
    default:
      timeZone = 'WIB'
      break
  }

  const formattedDate = date.toLocaleDateString('id', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  })

  const getHour = date.getHours() ? `${date.getHours()}` : `00`
  const getMin = date.getMinutes() ? `${date.getMinutes()}` : `00`
  const getSecond = date.getSeconds() ? `${date.getSeconds()}` : `00`

  const formattedTime = `${getHour}:${getMin}:${getSecond}`

  return [formattedDate, formattedTime, timeZone]
}

export const moneyFormat = (price: number) => {
  const pieces = price.toFixed().split('')
  let ii = pieces.length
  while ((ii -= 3) > 0) {
    pieces.splice(ii, 0, '.')
  }
  return `Rp. ` + pieces.join('')
}

export const isValidURL = (url: string) => {
  const pattern = new RegExp(
    `^(https?:\\/\\/)?` + // protocol
    `((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|` + // domain name
    `((\\d{1,3}\\.){3}\\d{1,3}))` + // OR ip (v4) address
    `(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*` + // port and path
    `(\\?[;&a-z\\d%_.~+=-]*)?` + // query string
      `(\\#[-a-z\\d_]*)?$`,
    `i`
  )
  return !!pattern.test(url)
}
