
import { parseDate } from '@/utils/Util'
import { Webinar } from '@/dto'
import moment from 'moment'
import 'fix-date'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Webinar',
  props: {
    startDate: String,
    expiredDate: String,
    onClickWebinar: Function,
    onClickPreTest: Function,
    onClickPostTest: Function,
    preTest: Array as any,
    postTest: Array as any,
    webinar: Object as Webinar | any,
    onClickRequestWebinarPlus: Function,
  },
  data() {
    return {
      coming: false,
      soon: false,
      live: false,
      notStartedYet: false,
      timer: 0 as number,
      shouldLive: false,
      openPre: false,
      openPost: false,
      minuteLeft: '' as any,
      dayLeft: '' as any,
    }
  },
  mounted() {
    this.refresh()

    if ((this.coming || this.soon) && !this.live) {
      window.setInterval(() => {
        this.refresh()
      }, 5000)
    }

    window.setInterval(() => {
      const isDone = this.$props.preTest.find(
        (e: any) => e.webinar_id === this.$props.webinar?.id
      )?.is_done
      const isDonePost = this.$props.postTest.find(
        (e: any) => e.webinar_id === this.$props.webinar?.id
      )?.is_done
      const preStart = this.$props.preTest.find(
        (e: any) => e.webinar_id === this.$props.webinar?.id
      )?.started_at
      const postStart = this.$props.postTest.find(
        (e: any) => e.webinar_id === this.$props.webinar?.id
      )?.started_at

      if (!isDone) {
        if (this.refreshTest(preStart)) {
          this.openPre = true
        }
      }

      if (!isDonePost) {
        if (this.refreshTest(postStart)) {
          this.openPost = true
        }
      }
    }, 5000)
  },
  methods: {
    refreshTest(date: string) {
      if (this.dateLong(date) <= this.dateNow()) {
        return true
      } else {
        return false
      }
    },

    refresh() {
      this.coming = false
      this.soon = false
      this.live = false
      this.notStartedYet = false
      this.shouldLive = false

      if (this.dateLong(this.$props.webinar.started_at) <= this.dateNow()) {
        this.shouldLive = true
      }

      if (this.dateOnLive(this.$props.webinar.started_at) >= this.dateNow()) {
        this.coming = true
        this.getDayLeft(this.$props.webinar.started_at)
      }

      if (
        this.dateOnLive(this.$props.webinar.started_at) <= this.dateNow() &&
        this.dateLong(this.$props.webinar.started_at) >= this.dateNow()
      ) {
        this.getMinuteLeft(this.$props.webinar.started_at)
        this.soon = true
      }

      if (
        this.dateLong(this.$props.webinar.started_at) <= this.dateNow() &&
        this.dateLong(this.$props.webinar.expired_at) >= this.dateNow()
      ) {
        this.coming = false
        this.live = true
        this.soon = false
      }

      if (this.dateLong(this.$props.webinar.started_at) < this.dateNow()) {
        this.notStartedYet = true
      }
    },

    getDayLeft(start: string) {
      const webinarStart = moment(this.dateLong(start))
      const dateNow = moment()
      this.dayLeft = webinarStart.diff(dateNow, 'days')
    },

    getMinuteLeft(start: string) {
      const webinarStart = moment(this.dateLong(start))
      const dateNow = moment()
      const hourLeft = webinarStart.diff(dateNow, 'hours')
      const minuteLeft = webinarStart.diff(dateNow, 'minutes')
      if (hourLeft >= 1) {
        this.minuteLeft = 'DIMULAI ' + hourLeft + ' JAM LAGI'
      } else if (minuteLeft >= 1) {
        this.minuteLeft =
          'DIMULAI ' + webinarStart.diff(dateNow, 'minutes') + ' MENIT LAGI'
      } else {
        this.minuteLeft = 'SEGERA DIMULAI'
      }
    },

    formatDate: function(date: string) {
      const [formattedDate, formattedTime, timeZone] = parseDate(date)
      return formattedDate + ' ' + formattedTime + ' ' + timeZone
    },
    realDate: function(start: string, end: string) {
      const [formattedDate, formattedTime, timeZone] = parseDate(start)
      const [formattedDateEnd, formattedTimeEnd, timeZoneEnd] = parseDate(end)
      return (
        formattedDate +
        ' ' +
        formattedTime +
        '-' +
        formattedTimeEnd +
        ' ' +
        timeZone
      )
    },
    dateLong(date: string | undefined) {
      const dt = new Date(date as string)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }

      return moment(dt).format('YYYY-MM-DD HH:mm:ss')
    },
    dateNow() {
      return moment().format('YYYY-MM-DD HH:mm:ss')
    },
    dateOnLive(date: string | undefined) {
      const dt = new Date(date as string)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }
      return moment(dt)
        .subtract(1, 'days')
        .format('YYYY-MM-DD HH:mm:ss')
    },
    checkPostTest(data: any, id: number) {
      const get = data.some(
        (el: any) => el.is_done === true && el.webinar_id == id
      )
      return get
    },

    webinarTimeFormatted(start: string, end: string) {
      const startDate = moment(start)
        .locale('id')
        .format('DD MMMM YYYY HH:mm')
      const endDate = moment(end)
        .locale('id')
        .format('HH:mm')
      return startDate + ' - ' + endDate + ' WIB'
    },

    isPreTestExist(preTest: any, webinarId: number) {
      if (preTest.length == 0) {
        return false
      } else {
        if (preTest.some((el: any) => el.webinar_id == webinarId)) {
          return true
        } else {
          return false
        }
      }
    },
  },
})
