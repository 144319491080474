/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import ResourceResponse from '@/dto/Response/ResourceResponse'
import { Voucher } from '@/dto'
import MainApi from '@/utils/Http'
import Cookies from 'js-cookie'

const Http = MainApi.getInstance()
export default class VoucherModel {
  protected endpoint =
    `${process.env.VUE_APP_BASE_URL}` + `${process.env.VUE_APP_SERVICE_VOUCHER}`
  protected privateEndpoint =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_VOUCHER_PRIVATE}`

  public static async list(): Promise<ResourceResponse<Voucher[]>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listStore() {
    const endpoint = new this().privateEndpoint + '/voucher-classroom/get-store'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listVouchers() {
    const endpoint =
      new this().privateEndpoint +
      '/voucher-classroom/count-voucher-classroom?per_page=1000'

    const {
      data: { data, success, class_total, vouchers_total },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
      class_total,
      vouchers_total,
    }
  }

  public static async listVouchersStore(query: string) {
    const endpoint =
      new this().privateEndpoint + '/voucher-classroom/count-voucher-classroom'

    const {
      data: { data, success, class_total, vouchers_total },
    } = await Http.get(`${endpoint}?store=${query}&per_page=1000`)

    return {
      success,
      errors: [],
      data,
      class_total,
      vouchers_total,
    }
  }

  public static async listRegisteredVouchers() {
    const endpoint = new this().endpoint + '/registered-user'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async searchVoucherClass(code: string, store: string) {
    const endpoint = new this().endpoint + '/voucher-classroom/search-voucher'

    const {
      data: { data, success, class_total, vouchers_total },
    } = await Http.get(`${endpoint}?code=${code}&store=${store}`)

    return {
      success,
      errors: [],
      data,
      class_total,
      vouchers_total,
    }
  }

  public static async add(payload: any) {
    const endpoint = new this().privateEndpoint

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async detail(id: number): Promise<ResourceResponse<Voucher>> {
    const endpoint = new this().privateEndpoint + '/voucher'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/show/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async detailRegisteredVouchers(id: string) {
    const endpoint = new this().endpoint + '/registered-user'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}?date=${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async update(id: number, payload: any) {
    const endpoint = new this().privateEndpoint
    console.log(payload)

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success },
    } = await Http.put(`${endpoint}/voucher/update/${id}`, payload, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async copiedCode(
    id: number,
    payload: Voucher
  ): Promise<ResourceResponse<Voucher>> {
    const endpoint = new this().privateEndpoint

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success },
    } = await Http.put(`${endpoint}/voucher/update/${id}`, payload, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async destroy(id: number): Promise<ResourceResponse<Voucher>> {
    const endpoint = new this().privateEndpoint

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/voucher/delete/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async reportVoucherClass(filter: string, store: string) {
    const endpoint =
      new this().privateEndpoint +
      '/voucher-classroom/voucher-classroom-report?filter[date]=' +
      filter +
      '&store=' +
      store

    console.log(endpoint)

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success },
    } = await Http.get(endpoint, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async reportVoucherClassAll() {
    const endpoint =
      new this().privateEndpoint + '/voucher-classroom/voucher-classroom-report'

    console.log(endpoint)

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success },
    } = await Http.get(endpoint, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async reportVoucherClassDetail(
    filter: string,
    store: string,
    classCode: string
  ) {
    const endpoint =
      new this().privateEndpoint +
      '/voucher-classroom/voucher-classroom-report?filter[date]=' +
      filter +
      '&store=' +
      store +
      '&code=' +
      classCode

    console.log(endpoint)

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success },
    } = await Http.get(endpoint, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async voucherAvailable() {
    const endpoint = new this().privateEndpoint + '/voucher/voucher-available'

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}?per_page=1000`, { headers })

    return {
      success,
      errors: [],
      data,
      pages,
    }
  }

  public static async listCoupon() {
    const endpoint =
      new this().privateEndpoint + '/coupon/show-coupon?per_page=100'
    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listDetailCoupon(id: number) {
    const endpoint = new this().privateEndpoint + '/coupon/show/' + id
    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async checkCoupon(
    code: string,
    classes: string[],
    total: number
  ) {
    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const endpoint = new this().endpoint + '/coupon/redeem-coupon/'
    const payload = {
      code: code,
      classes: classes,
      total_bought: total.toString(),
    }
    const {
      data: { data, success, errors },
    } = await Http.post(endpoint, payload, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async addCoupon(payload: any) {
    const endpoint = new this().privateEndpoint + '/coupon/add'
    const body = {
      name: payload.name,
      code: payload.code,
      price: payload.price,
      percentage: payload.percentage,
      quota: Number(payload.quota),
      start_at: payload.startAt,
      end_at: payload.endAt,
    }

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, body)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async updateCoupon(id: number, payload: any) {
    const endpoint = new this().privateEndpoint + '/coupon/update/' + id
    const body = {
      name: payload.name,
      code: payload.code,
      price: payload.price,
      percentage: payload.percentage,
      quota: Number(payload.quota),
      start_at: payload.startAt,
      end_at: payload.endAt,
    }
    const {
      data: { data, success },
    } = await Http.put(`${endpoint}`, body)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async deleteCoupon(id: number) {
    const endpoint = new this().privateEndpoint + '/coupon/delete/' + id
    const {
      data: { data, success },
    } = await Http.delete(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async voucherGenerateList() {
    const endpoint =
      new this().privateEndpoint + '/voucher-generate/show-voucher-generate'

    const token = Cookies.getJSON('auth')

    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}?per_page=1000`)

    return {
      success,
      errors: [],
      data,
      pages,
    }
  }

  public static async getVoucherUser(uuid: any) {
    const endpoint =
      new this().privateEndpoint + '/voucher-classroom/user/' + uuid

    const token = Cookies.getJSON('auth')

    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      errors: [],
      data,
      pages,
    }
  }

  public static async isUserPrakerja(classroomId: string) {
    const endpoint = new this().privateEndpoint + '/is-prakerja/' + classroomId

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      data,
    }
  }
}
