<template>
  <div
    class="webinar-item d-flex p-2 justify-content-between bg-blue-sd-3 mb-2"
    style="border-radius: 10px;"
  >
    <div
      class="webinar-icon-wrapper d-flex justify-content-center align-items-center"
    >
      <img
        :src="classroomIcon"
        alt="Classroom"
        class="webinar-icon"
        style="border-radius: 5px;"
      />
    </div>
    <div
      class="full-width d-flex py-2 px-1 justify-content-start align-items-center"
      style="flex-direction: column;"
    >
      <p class="text-white full-width px-2" style="font-weight: 900;">
        {{ webinarName }}
      </p>
      <p class="text-white d-block full-width px-2 text-left">
        {{ realDate(startDate, expiredDate) }}
      </p>
      <p
        class="pl-2 full-width d-block text-left font-900"
        v-if="soon"
        style="color:#FA4C4F"
      >
        WEBINAR AKAN BERLANGSUNG
      </p>
      <p
        v-if="notStartedYet"
        class="pl-2 full-width d-block text-left font-900"
        :style="live == true ? 'color:#14BC2F;' : 'color:#C4C4C4;'"
      >
        {{
          live == true ? 'WEBINAR SEDANG BERLANGSUNG' : 'WEBINAR TELAH SELESAI'
        }}
      </p>
      <div class="w-100 webinar-quiz px-0 px-md-2">
        <!-- Pre-Test -->
        <div v-if="preTest?.length > 0">
          <div v-for="preTest in preTest" :key="preTest.id">
            <a
              v-if="preTest.webinar_id == idWebinar"
              class="p-2 px-lg-4  font-900 webinar-quiz-items my-2 mr-0 ml-2 ml-md-0 text-center d-flex webinar-text-sm"
              style="border-radius:10px; cursor:pointer;"
              :class="
                preTest?.is_done == false || webinarDone == false
                  ? (openPre || dateLong(preTest.started_at) <= dateNow()) &&
                    dateLong(preTest.ended_at) >= dateNow()
                    ? 'bg-blue-sd-1 text-white px-3'
                    : 'bg-webinar-off text-white px-3'
                  : preTest?.is_done
                  ? 'bg-blue-sd-2 text-white px-1'
                  : 'bg-blue-sd-1 text-white px-1'
              "
              @click="onClickPreTest(preTest)"
            >
              Pre-Test
              <svg
                v-if="preTest.is_done"
                class="ml-2 align-self-center svg-img"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                style="color:#ffc200;"
                viewBox="0 0 16 16"
              >
                <path
                  d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"
                />
                <path
                  d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"
                />
              </svg>
            </a>
          </div>
        </div>
        <!-- Webinar -->
        <a
          class="p-2 px-lg-4 font-900 webinar-quiz-items m-2 text-center d-flex webinar-text-sm"
          style="border-radius:10px; cursor:pointer;"
          :class="
            shouldLive && live
              ? 'bg-webinar-on text-white px-2'
              : checkPostTest(postTest, idWebinar)
              ? 'bg-blue-sd-2 text-white px-1'
              : 'bg-webinar-off text-white px-1'
          "
          @click.prevent="onClickWebinar(idWebinar, startDate, expiredDate)"
        >
          Webinar
          <svg
            v-if="checkPostTest(postTest, idWebinar)"
            class="ml-2 align-self-center svg-img"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            style="color:#ffc200;"
            viewBox="0 0 16 16"
          >
            <path
              d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"
            />
            <path
              d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"
            />
          </svg>
        </a>
        <!-- Post-Test -->
        <div v-if="postTest?.length > 0">
          <div v-for="postTest in postTest" :key="postTest.id">
            <a
              v-if="postTest?.webinar_id == idWebinar"
              class="p-2 px-lg-4 font-900 webinar-quiz-items my-2 ml-0 mr-2 mr-md-0 text-center d-flex webinar-text-sm"
              style="border-radius:10px; cursor:pointer;"
              :class="
                postTest?.is_done == false || webinarDone == false
                  ? (openPost || dateLong(postTest.started_at) <= dateNow()) &&
                    dateLong(postTest.ended_at) >= dateNow()
                    ? 'bg-blue-sd-1 text-white px-3'
                    : 'bg-webinar-off text-white px-3'
                  : postTest?.is_done
                  ? 'bg-blue-sd-2 text-white px-1'
                  : 'bg-blue-sd-1 text-white px-1'
              "
              @click="onClickPostTest(postTest)"
            >
              Post-Test
              <svg
                v-if="postTest?.is_done"
                class="ml-2 align-self-center svg-img"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                style="color:#ffc200;"
                viewBox="0 0 16 16"
              >
                <path
                  d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"
                />
                <path
                  d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"
                />
              </svg>
            </a>
          </div>
        </div>
        <a
          v-if="
            webinarDetail?.can_request_plus &&
              dateLong(webinarDetail.request_plus_expired_at) >= dateNow()
          "
          @click="
            onClickRequestWebinarPlus(
              webinarDetail?.id,
              webinarDetail?.request_plus_started_at,
              webinarDetail?.request_plus_expired_at,
              webinarDetail?.classroom.name
            )
          "
          class="p-2 px-lg-4 font-900 webinar-quiz-items m-2 text-center d-flex webinar-text-sm btn text-white"
          :class="
            dateLong(webinarDetail.request_plus_started_at) <= dateNow()
              ? 'bg-blue-sd-1'
              : 'bg-webinar-off'
          "
          style="border-radius:10px; cursor:pointer;"
        >
          Request Webinar Plus
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { parseDate } from '@/utils/Util'
import moment from 'moment'
import 'fix-date'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Webinar',
  props: {
    idWebinar: Number,
    classroomTitle: String,
    startDate: String,
    expiredDate: String,
    link: String,
    classroomIcon: String,
    onClickWebinar: Function,
    onClickPreTest: Function,
    onClickPostTest: Function,
    preTest: Array as any,
    postTest: Array as any,
    webinarDone: Boolean,
    webinarName: String,
    webinarDetail: Object,
    onClickRequestWebinarPlus: Function,
  },
  data() {
    return {
      soon: false,
      live: false,
      notStartedYet: false,
      timer: 0 as number,
      shouldLive: false,
      openPre: false,
      openPost: false,
    }
  },
  mounted() {
    this.refresh()

    if (this.soon && !this.live) {
      window.setInterval(() => {
        this.refresh()
      }, 5000)
    }

    window.setInterval(() => {
      const isDone = this.$props.preTest.find(
        (e: any) => e.webinar_id === this.idWebinar
      )?.is_done
      const isDonePost = this.$props.postTest.find(
        (e: any) => e.webinar_id === this.idWebinar
      )?.is_done
      const preStart = this.$props.preTest.find(
        (e: any) => e.webinar_id === this.idWebinar
      )?.started_at
      const postStart = this.$props.postTest.find(
        (e: any) => e.webinar_id === this.idWebinar
      )?.started_at

      if (!isDone) {
        if (this.refreshTest(preStart)) {
          this.openPre = true
        }
      }

      if (!isDonePost) {
        if (this.refreshTest(postStart)) {
          this.openPost = true
        }
      }
    }, 5000)
  },
  methods: {
    refreshTest(date: string) {
      if (this.dateLong(date) <= this.dateNow()) {
        return true
      } else {
        return false
      }
    },

    refresh() {
      this.soon = false
      this.live = false
      this.notStartedYet = false
      this.shouldLive = false

      if (this.dateLong(this.$props.startDate) <= this.dateNow()) {
        this.shouldLive = true
      }

      if (
        this.dateOnLive(this.$props.startDate) <= this.dateNow() &&
        this.dateLong(this.$props.startDate) >= this.dateNow()
      ) {
        this.soon = true
      }

      if (
        this.dateLong(this.$props.startDate) <= this.dateNow() &&
        this.dateLong(this.$props.expiredDate) >= this.dateNow()
      ) {
        this.live = true
        this.soon = false
      }

      if (this.dateLong(this.$props.startDate) < this.dateNow()) {
        this.notStartedYet = true
      }
    },

    formatDate: function(date: string) {
      const [formattedDate, formattedTime, timeZone] = parseDate(date)
      return formattedDate + ' ' + formattedTime + ' ' + timeZone
    },
    realDate: function(start: string, end: string) {
      const [formattedDate, formattedTime, timeZone] = parseDate(start)
      const [formattedDateEnd, formattedTimeEnd, timeZoneEnd] = parseDate(end)
      return (
        formattedDate +
        ' ' +
        formattedTime +
        '-' +
        formattedTimeEnd +
        ' ' +
        timeZone
      )
    },
    dateLong(date: string | undefined) {
      const dt = new Date(date as string)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }

      return moment(dt).format('YYYY-MM-DD HH:mm:ss')
    },
    dateNow() {
      return moment().format('YYYY-MM-DD HH:mm:ss')
    },
    dateOnLive(date: string | undefined) {
      const dt = new Date(date as string)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }
      return moment(dt)
        .subtract(60, 'minutes')
        .format('YYYY-MM-DD HH:mm:ss')
    },
    checkPostTest(data: any, id: number) {
      const get = data.some(
        (el: any) => el.is_done === true && el.webinar_id == id
      )
      return get
    },
  },
})
</script>

<style scoped>
.webinar-item {
  flex-direction: column;
}
.webinar-icon {
  width: 100%;
}
.webinar-icon-wrapper {
  width: 100%;
}
.webinar-link {
  display: block;
  text-align: center;
  width: 100%;
}
.webinar-quiz {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.webinar-join {
  display: block;
  flex-direction: column;
}
.webinar-quiz-items {
  flex-shrink: 1;
  flex-grow: 1;
  place-content: center;
  align-items: center;
}
.webinar-text-sm {
  font-size: 1rem;
}
.bg-webinar-on {
  background: #14bc2f;
}
.bg-webinar-off {
  background: #c4c4c4;
}
.svg-img {
  width: 20px;
  height: 20px;
}
@media (min-width: 768px) {
  .webinar-join {
    display: inline;
  }
  .webinar-item {
    flex-direction: row;
  }
  .webinar-icon-wrapper {
    width: 35%;
  }
  .webinar-link {
    display: inline;
  }
  .webinar-quiz {
    justify-content: start;
  }
  .webinar-quiz-items {
    flex-shrink: 1;
    flex-grow: 0;
    text-align: center;
  }
}
@media (max-width: 356px) {
  .svg-img {
    width: 15px;
    height: 15px;
  }
  .webinar-text-sm {
    font-size: 0.8rem;
  }
}
</style>
