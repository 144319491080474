import ResourceResponse from '@/dto/Response/ResourceResponse'
import { Quiz } from '@/dto'
import MainApi from '@/utils/Http'
const Http = MainApi.getInstance()
export default class QuizModel {
  protected endpoint =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_CLASSROOM}`

  protected privateEndpoint =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_CLASSROOM_PRIVATE}`

  protected privateEndpointV2 =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_CLASSROOM_PRIVATE_V2}`

  public static async list(id: number): Promise<ResourceResponse<Quiz>> {
    const endpoint = new this().privateEndpointV2 + '/quiz/' + id

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listByClassroom(
    ClassroomId: any
  ): Promise<ResourceResponse<Quiz>> {
    const endpoint =
      new this().privateEndpoint + '/quiz/' + ClassroomId + '/classroom'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listItemsById(id: any) {
    const endpoint = new this().privateEndpoint + '/quiz/' + id + '/items'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async add(id: any, payload: any) {
    const endpoint = new this().privateEndpointV2 + '/quiz/' + id + '/submit'

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, payload)
    // .then(res => {
    //   return res.data
    // })
    // .catch(err => {
    //   return err.response.data
    // })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async detail(id: number): Promise<ResourceResponse<Quiz>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async update(
    id: number,
    payload: Quiz
  ): Promise<ResourceResponse<Quiz>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.put(`${endpoint}/${id}`, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async destroy(id: number): Promise<ResourceResponse<Quiz>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }
}
