
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalRequestWebinar',
  props: {
    show: Boolean,
    imageUrl: String,
    firstText: String,
    secondText: String,
    firstButton: String,
    firstButtonAction: Function,
    secondButton: String,
    secondButtonAction: Function,
    duration: Number,
    imageWidth: String,
    next: Function,
    showInput: Boolean,
    reason: String,
    classroom: String,
    error: Boolean,
  },
  methods: {},
})
