<template>
  <transition name="fade" appear>
    <div v-if="show" class="opacity-50 fixed inset-0 z-50 bg-black"></div>
  </transition>
  <transition
    enter-active-class="animated bounceIn"
    leave-active-class="animated zoomOut"
    appear
  >
    <div
      v-if="show"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex z-51"
    >
      <div class="relative w-full md:w-1/3 my-6 mx-4 md:mx-auto">
        <!--content-->
        <div
          v-click-away="close"
          class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[80vh]"
        >
          <!--header-->
          <div class="flex items-start justify-end p-3 pb-0 rounded-t">
            <div
              v-if="closeText"
              class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
              @click="close()"
            >
              <svg
                class="fill-current text-[#383838]"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                ></path>
              </svg>
            </div>
            <div
              v-else
              class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
              @click="submit()"
            >
              <svg
                class="fill-current text-[#383838]"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                ></path>
              </svg>
            </div>
          </div>
          <!--body-->
          <div
            class="pt-0 pb-6 px-6 flex-auto overflow-x-hiden overflow-y-auto"
          >
            <img
              :src="
                imageUrl
                  ? imageUrl
                  : '/assets/images/modal/illustration-test-not-completed.svg'
              "
              class="w-1/2 m-auto"
              alt=""
            />
            <p
              class="font-desain font-semibold my-3 text-center text-[#212121] text-xl"
            >
              {{ title }}
            </p>
            <p
              class="font-solusi font-medium text-sm text-center text-[#383838]"
            >
              {{ textOne }}
            </p>
            <p
              v-show="textTwo != ''"
              class="font-desain text-lg text-center text-[#2FBD71] font-semibold mt-2"
            >
              {{ textTwo }}
            </p>
            <div class="flex justify-evenly mt-8">
              <button
                v-if="closeText != ''"
                @click="close()"
                class="w-36 py-2 font-solusi font-semibold rounded-full outline-none focus:outline-none border-dark-blue text-[#00446F]"
                :disabled="loading"
              >
                {{ closeText }}
              </button>
              <button
                v-if="submitText != ''"
                @click="submit()"
                class="bg-[#00446F] w-36 py-2 text-white font-solusi font-semibold rounded-full outline-none focus:outline-none flex items-center justify-center"
                :class="loading ? 'cursor-wait' : 'cursor-pointer'"
                :disabled="loading"
              >
                {{ submitText }}
                <svg
                  v-if="loading"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="spinner"
                  class="w-4 h-4 animate-spin ml-2"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="#FFFFFF"
                    d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal Kuis',
  props: {
    show: Boolean,
    imageUrl: String,
    title: String,
    textOne: String,
    textTwo: String,
    closeText: String,
    close: Function,
    submitText: String,
    submit: Function,
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style></style>
