<template>
  <div
    class="flex flex-col rounded-lg md:rounded-md shadow-md md:hover:shadow-lg mb-4 p-2 bg-[#FFFFFF] border border-gray-50 duration-300"
  >
    <div class="flex flex-row space-x-3 md:mb-0 mb-3">
      <img
        :src="webinar?.thumbnail ? webinar.thumbnail : classroom?.thumbnail"
        class="w-36 md:w-64 h-36 rounded-md object-cover object-center"
        alt=""
      />
      <div
        class="flex-auto flex flex-col justify-between w-full overflow-hidden h-auto md:h-36"
      >
        <div class="flex flex-col flex-grow">
          <p
            class="font-desain text-xl font-semibold text-[#383838] mb-0 line-clamp-2 overflow-hidden"
          >
            {{ material }}
          </p>
          <p v-if="note" class="font-solusi text-[#757575] mb-0 truncate">
            {{ note }}
          </p>
          <p
            v-if="classroom.mentor_data.length > 0"
            class="font-solusi text-[#212121]"
          >
            {{ classroom.mentor_data[0].name }}
          </p>
        </div>
        <p
          v-if="webinar.status == 1 && coming"
          class="font-solusi text-[#009DFF] font-semibold "
        >
          AKAN DIMULAI {{ dayLeft }} HARI LAGI
        </p>
        <p
          v-if="webinar.status == 1 && soon"
          class="font-solusi text-[#FB8500] font-semibold "
        >
          AKAN {{ minuteLeft }}
        </p>
        <p
          v-if="webinar.status == 1 && notStartedYet"
          class="font-solusi font-semibold "
          :class="live ? 'text-[#2FBD71]' : 'text-[#9E9E9E]'"
        >
          {{
            live ? 'KONSULTASI SEDANG BERLANGSUNG' : 'KONSULTASI TELAH BERAKHIR'
          }}
        </p>
        <div class="flex items-end justify-between">
          <p v-if="webinar.status == 1" class="font-solusi text-[#212121]">
            {{
              webinar.started_at == ''
                ? webinarTimeFormatted(webinar.request_started_at, '1')
                : webinarTimeFormatted(webinar.started_at, webinar.expired_at)
            }}
          </p>
          <p v-else-if="webinar.status == 0" class="font-solusi text-[#212121]">
            {{ webinarRequestTimeFormatted(webinar.request_started_at) }}
          </p>
          <button
            v-else
            @click="reapply"
            class="w-fit-content font-solusi text-base rounded-full px-4 py-2 font-semibold flex items-center border duration-300"
            :class="
              quota > 0
                ? 'text-[#00446F] border-[#00446F] hover:text-[#FFFFFF] hover:bg-[#00446F]'
                : 'text-[#9E9E9E] bg-[#F5F6F8] cursor-not-allowed'
            "
            :disabled="quota <= 0"
          >
            Request Ulang
          </button>
          <div class="hidden md:flex">
            <p
              v-if="webinar.status == 0"
              class="font-solusi text-[#FB8500] font-semibold"
            >
              Menunggu Approval
            </p>
            <button
              v-else-if="webinar.status == 1"
              @click="
                joinPrivateWebinar(
                  webinar.link,
                  webinar.started_at,
                  webinar.expired_at
                )
              "
              class="w-fit-content font-solusi text-base rounded-full px-4 py-2 font-semibold flex border items-center"
              :class="
                shouldLive && live
                  ? 'text-[#FFFFFF] bg-[#00446F]'
                  : 'text-[#9E9E9E] bg-[#F5F6F8]'
              "
            >
              Join Konsultasi
            </button>
            <p v-else class="font-solusi text-[#DE1306] font-semibold">
              Pengajuan Ditolak
            </p>
          </div>
        </div>
      </div>
    </div>
    <p
      v-if="webinar.status == -1"
      class="hidden md:block font-solusi text-[#757575] text-right text-sm"
    >
      ({{ webinar.reject_note }})
    </p>
    <div class="flex md:hidden flex-row items-start space-x-2 justify-between">
      <p
        v-if="webinar.status == 0"
        class="font-solusi text-[#FB8500] font-semibold flex-shrink-0"
      >
        Menunggu Approval
      </p>
      <button
        v-else-if="webinar.status == 1"
        @click="
          joinPrivateWebinar(
            webinar.link,
            webinar.started_at,
            webinar.expired_at
          )
        "
        class="w-fit-content font-solusi text-base rounded-full px-4 py-2 font-semibold flex items-center flex-shrink-0"
        :class="
          shouldLive && live
            ? 'text-[#FFFFFF] bg-[#00446F]'
            : 'text-[#9E9E9E] bg-[#F5F6F8]'
        "
      >
        Join Konsultasi
      </button>
      <p v-else class="font-solusi text-[#DE1306] font-semibold flex-shrink-0">
        Pengajuan Ditolak
      </p>
      <p
        v-if="webinar.status == -1"
        class="font-solusi text-[#757575] ml-3 text-sm"
      >
        ({{ webinar.reject_note }})
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { parseDate } from '@/utils/Util'
import { PrivateWebinar, Classroom } from '@/dto'
import moment from 'moment'
import 'fix-date'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Private Webinar',
  props: {
    startDate: String,
    expiredDate: String,
    joinPrivateWebinar: Function,
    quota: Number,
    webinar: Object as any,
    classroom: Object as any,
  },
  data() {
    return {
      coming: false,
      soon: false,
      live: false,
      notStartedYet: false,
      timer: 0 as number,
      shouldLive: false,
      minuteLeft: '' as any,
      dayLeft: '' as any,
      material: '' as string,
      note: '' as string,
    }
  },
  emits: ['reApply'],
  mounted() {
    this.refresh()
    const splitNote = this.$props.webinar.note.split('\n')
    if (splitNote.length > 1) {
      this.material = splitNote[0]
      this.note = splitNote[1]
    } else {
      this.material = this.$props.webinar.note
    }

    if ((this.coming || this.soon) && !this.live) {
      window.setInterval(() => {
        this.refresh()
      }, 5000)
    }
  },
  methods: {
    reapply() {
      this.$emit('reApply', this.$props.webinar)
    },
    refreshTest(date: string) {
      if (this.dateLong(date) <= this.dateNow()) {
        return true
      } else {
        return false
      }
    },

    refresh() {
      this.coming = false
      this.soon = false
      this.live = false
      this.notStartedYet = false
      this.shouldLive = false

      if (this.$props.webinar.started_at != '') {
        if (this.dateLong(this.$props.webinar.started_at) <= this.dateNow()) {
          this.shouldLive = true
        }

        if (this.dateOnLive(this.$props.webinar.started_at) >= this.dateNow()) {
          this.coming = true
          this.getDayLeft(this.$props.webinar.started_at)
        }

        if (
          this.dateOnLive(this.$props.webinar.started_at) <= this.dateNow() &&
          this.dateLong(this.$props.webinar.started_at) >= this.dateNow()
        ) {
          this.getMinuteLeft(this.$props.webinar.started_at)
          this.soon = true
        }

        if (
          this.dateLong(this.$props.webinar.started_at) <= this.dateNow() &&
          this.dateLong(this.$props.webinar.expired_at) >= this.dateNow()
        ) {
          this.coming = false
          this.live = true
          this.soon = false
        }

        if (this.dateLong(this.$props.webinar.started_at) < this.dateNow()) {
          this.notStartedYet = true
        }
      } else {
        if (
          this.dateLong(this.$props.webinar.request_started_at) <=
          this.dateNow()
        ) {
          this.shouldLive = true
        }

        if (
          this.dateOnLive(this.$props.webinar.request_started_at) >=
          this.dateNow()
        ) {
          this.coming = true
          this.getDayLeft(this.$props.webinar.request_started_at)
        }

        if (
          this.dateOnLive(this.$props.webinar.request_started_at) <=
            this.dateNow() &&
          this.dateLong(this.$props.webinar.request_started_at) >=
            this.dateNow()
        ) {
          this.getMinuteLeft(this.$props.webinar.request_started_at)
          this.soon = true
        }

        const expiredAt = moment(this.$props.webinar.request_started_at)
          .add(1, 'hours')
          .format('YYYY-MM-DD HH:mm:ss')
        if (
          this.dateLong(this.$props.webinar.request_started_at) <=
            this.dateNow() &&
          this.dateLong(expiredAt) >= this.dateNow()
        ) {
          this.coming = false
          this.live = true
          this.soon = false
        }

        if (
          this.dateLong(this.$props.webinar.request_started_at) < this.dateNow()
        ) {
          this.notStartedYet = true
        }
      }
    },

    getDayLeft(start: string) {
      const webinarStart = moment(this.dateLong(start))
      const dateNow = moment()
      this.dayLeft = webinarStart.diff(dateNow, 'days')
    },

    getMinuteLeft(start: string) {
      const webinarStart = moment(this.dateLong(start))
      const dateNow = moment()
      const hourLeft = webinarStart.diff(dateNow, 'hours')
      const minuteLeft = webinarStart.diff(dateNow, 'minutes')
      if (hourLeft >= 1) {
        this.minuteLeft = 'DIMULAI ' + hourLeft + ' JAM LAGI'
      } else if (minuteLeft >= 1) {
        this.minuteLeft =
          'DIMULAI ' + webinarStart.diff(dateNow, 'minutes') + ' MENIT LAGI'
      } else {
        this.minuteLeft = 'SEGERA DIMULAI'
      }
    },

    formatDate: function(date: string) {
      const [formattedDate, formattedTime, timeZone] = parseDate(date)
      return formattedDate + ' ' + formattedTime + ' ' + timeZone
    },
    realDate: function(start: string, end: string) {
      const [formattedDate, formattedTime, timeZone] = parseDate(start)
      const [formattedDateEnd, formattedTimeEnd, timeZoneEnd] = parseDate(end)
      return (
        formattedDate +
        ' ' +
        formattedTime +
        '-' +
        formattedTimeEnd +
        ' ' +
        timeZone
      )
    },
    dateLong(date: string | undefined) {
      const dt = new Date(date as string)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }

      return moment(dt).format('YYYY-MM-DD HH:mm:ss')
    },
    dateNow() {
      return moment().format('YYYY-MM-DD HH:mm:ss')
    },
    dateOnLive(date: string | undefined) {
      const dt = new Date(date as string)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }
      return moment(dt)
        .subtract(1, 'days')
        .format('YYYY-MM-DD HH:mm:ss')
    },

    webinarTimeFormatted(start: string, end: string) {
      const startDate = moment(start)
        .locale('id')
        .format('DD MMMM YYYY HH:mm')
      if (end == '1') {
        const endDate = moment(start)
          .add(1, 'hours')
          .locale('id')
          .format('HH:mm')
        return startDate + ' - ' + endDate + ' WIB'
      } else {
        const endDate = moment(end)
          .locale('id')
          .format('HH:mm')
        return startDate + ' - ' + endDate + ' WIB'
      }
    },

    webinarRequestTimeFormatted(date: string) {
      const startDate = moment(date)
        .locale('id')
        .format('DD MMMM YYYY HH:mm')
      return startDate + ' WIB'
    },
  },
})
</script>
