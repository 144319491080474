
import { parseDate } from '@/utils/Util'
import { PrivateWebinar, Classroom } from '@/dto'
import moment from 'moment'
import 'fix-date'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Private Webinar',
  props: {
    startDate: String,
    expiredDate: String,
    joinPrivateWebinar: Function,
    quota: Number,
    webinar: Object as any,
    classroom: Object as any,
  },
  data() {
    return {
      coming: false,
      soon: false,
      live: false,
      notStartedYet: false,
      timer: 0 as number,
      shouldLive: false,
      minuteLeft: '' as any,
      dayLeft: '' as any,
      material: '' as string,
      note: '' as string,
    }
  },
  emits: ['reApply'],
  mounted() {
    this.refresh()
    const splitNote = this.$props.webinar.note.split('\n')
    if (splitNote.length > 1) {
      this.material = splitNote[0]
      this.note = splitNote[1]
    } else {
      this.material = this.$props.webinar.note
    }

    if ((this.coming || this.soon) && !this.live) {
      window.setInterval(() => {
        this.refresh()
      }, 5000)
    }
  },
  methods: {
    reapply() {
      this.$emit('reApply', this.$props.webinar)
    },
    refreshTest(date: string) {
      if (this.dateLong(date) <= this.dateNow()) {
        return true
      } else {
        return false
      }
    },

    refresh() {
      this.coming = false
      this.soon = false
      this.live = false
      this.notStartedYet = false
      this.shouldLive = false

      if (this.$props.webinar.started_at != '') {
        if (this.dateLong(this.$props.webinar.started_at) <= this.dateNow()) {
          this.shouldLive = true
        }

        if (this.dateOnLive(this.$props.webinar.started_at) >= this.dateNow()) {
          this.coming = true
          this.getDayLeft(this.$props.webinar.started_at)
        }

        if (
          this.dateOnLive(this.$props.webinar.started_at) <= this.dateNow() &&
          this.dateLong(this.$props.webinar.started_at) >= this.dateNow()
        ) {
          this.getMinuteLeft(this.$props.webinar.started_at)
          this.soon = true
        }

        if (
          this.dateLong(this.$props.webinar.started_at) <= this.dateNow() &&
          this.dateLong(this.$props.webinar.expired_at) >= this.dateNow()
        ) {
          this.coming = false
          this.live = true
          this.soon = false
        }

        if (this.dateLong(this.$props.webinar.started_at) < this.dateNow()) {
          this.notStartedYet = true
        }
      } else {
        if (
          this.dateLong(this.$props.webinar.request_started_at) <=
          this.dateNow()
        ) {
          this.shouldLive = true
        }

        if (
          this.dateOnLive(this.$props.webinar.request_started_at) >=
          this.dateNow()
        ) {
          this.coming = true
          this.getDayLeft(this.$props.webinar.request_started_at)
        }

        if (
          this.dateOnLive(this.$props.webinar.request_started_at) <=
            this.dateNow() &&
          this.dateLong(this.$props.webinar.request_started_at) >=
            this.dateNow()
        ) {
          this.getMinuteLeft(this.$props.webinar.request_started_at)
          this.soon = true
        }

        const expiredAt = moment(this.$props.webinar.request_started_at)
          .add(1, 'hours')
          .format('YYYY-MM-DD HH:mm:ss')
        if (
          this.dateLong(this.$props.webinar.request_started_at) <=
            this.dateNow() &&
          this.dateLong(expiredAt) >= this.dateNow()
        ) {
          this.coming = false
          this.live = true
          this.soon = false
        }

        if (
          this.dateLong(this.$props.webinar.request_started_at) < this.dateNow()
        ) {
          this.notStartedYet = true
        }
      }
    },

    getDayLeft(start: string) {
      const webinarStart = moment(this.dateLong(start))
      const dateNow = moment()
      this.dayLeft = webinarStart.diff(dateNow, 'days')
    },

    getMinuteLeft(start: string) {
      const webinarStart = moment(this.dateLong(start))
      const dateNow = moment()
      const hourLeft = webinarStart.diff(dateNow, 'hours')
      const minuteLeft = webinarStart.diff(dateNow, 'minutes')
      if (hourLeft >= 1) {
        this.minuteLeft = 'DIMULAI ' + hourLeft + ' JAM LAGI'
      } else if (minuteLeft >= 1) {
        this.minuteLeft =
          'DIMULAI ' + webinarStart.diff(dateNow, 'minutes') + ' MENIT LAGI'
      } else {
        this.minuteLeft = 'SEGERA DIMULAI'
      }
    },

    formatDate: function(date: string) {
      const [formattedDate, formattedTime, timeZone] = parseDate(date)
      return formattedDate + ' ' + formattedTime + ' ' + timeZone
    },
    realDate: function(start: string, end: string) {
      const [formattedDate, formattedTime, timeZone] = parseDate(start)
      const [formattedDateEnd, formattedTimeEnd, timeZoneEnd] = parseDate(end)
      return (
        formattedDate +
        ' ' +
        formattedTime +
        '-' +
        formattedTimeEnd +
        ' ' +
        timeZone
      )
    },
    dateLong(date: string | undefined) {
      const dt = new Date(date as string)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }

      return moment(dt).format('YYYY-MM-DD HH:mm:ss')
    },
    dateNow() {
      return moment().format('YYYY-MM-DD HH:mm:ss')
    },
    dateOnLive(date: string | undefined) {
      const dt = new Date(date as string)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }
      return moment(dt)
        .subtract(1, 'days')
        .format('YYYY-MM-DD HH:mm:ss')
    },

    webinarTimeFormatted(start: string, end: string) {
      const startDate = moment(start)
        .locale('id')
        .format('DD MMMM YYYY HH:mm')
      if (end == '1') {
        const endDate = moment(start)
          .add(1, 'hours')
          .locale('id')
          .format('HH:mm')
        return startDate + ' - ' + endDate + ' WIB'
      } else {
        const endDate = moment(end)
          .locale('id')
          .format('HH:mm')
        return startDate + ' - ' + endDate + ' WIB'
      }
    },

    webinarRequestTimeFormatted(date: string) {
      const startDate = moment(date)
        .locale('id')
        .format('DD MMMM YYYY HH:mm')
      return startDate + ' WIB'
    },
  },
})
